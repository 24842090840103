import { createSlice } from '@reduxjs/toolkit';

const NoticeSlice = createSlice({
  name: 'notice',
  initialState: {
    noticeList: [],
    status: 'empty',
    // empty fetching filled
  },
  reducers: {
    setNotice(state, action) {
      state.noticeList = action.payload.announcements;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
});

export const {
  setNotice,
  setStatus,
} = NoticeSlice.actions;

export default NoticeSlice.reducer;
