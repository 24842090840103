import axios from 'axios';
import { md5 } from 'hash-wasm';
import MockAdapter from 'axios-mock-adapter';
import qs from 'qs';
import config from '../config.json';

axios.defaults.baseURL = config.baseURL;

export const signInRequest = async (email, password) => {
  if (config.mocking.includes('sign-in')) {
    const mock = new MockAdapter(axios);
    mock.onPost('/user/login')
      .reply(async () => {
        await new Promise((resolve) => setTimeout(resolve, 500));
        return [200, JSON.stringify({
          jwt_token: 'sample_token',
        })];
      });
  }
  return axios.post('/user/login', qs.stringify({
    email,
    password: await md5(password),
  }))
    .catch((error) => {
      if (error.response) {
        throw Error('用户名或密码错误');
      } else if (error.request) {
        throw Error('网络连接错误');
      }
      throw Error('未知错误');
    })
    .then((response) => response.data);
};

export default { signInRequest };
