import validator from 'email-validator';
import config from './config.json';

export const validateEmail = (email) => {
  if (validator.validate(email)) {
    return '';
  }
  if (email.length === 0) return ' ';
  return '邮箱格式错误';
};

export const validatePassword = (password) => {
  if (password.length === 0) return ' ';
  if (password.length < config.password.minLength) {
    return '密码过短';
  }
  if (password.length > config.password.maxLength) {
    return '密码过长';
  }
  return '';
};

export const validateRedundantPassword = (password, redundantPassword) => {
  const msg = validatePassword(password);
  if (msg.length !== 0) {
    return msg;
  }
  if (password !== redundantPassword) {
    return '密码验证错误';
  }
  return '';
};

export const validateUsername = (name) => {
  if (name.length === 0) return ' ';
  if (name.length < config.username.minLength) {
    return '用户名过短';
  }
  if (name.length > config.username.maxLength) {
    return '用户名过长';
  }
  return '';
};

export const validateVerifyCode = (code) => {
  if (code.length === 0) return ' ';
  if (code.length !== config.verifyCode.length) {
    return '验证码长度不匹配';
  }
  return '';
};

export default {
  validateEmail,
  validatePassword,
  validateUsername,
  validateRedundantPassword,
};
