import React from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import {
  Snackbar,
  Alert,
} from '@mui/material';
import PropTypes from 'prop-types';
import TopBar from './TopBar';
import Main from './Main';
import store, { subscribe } from './store';
import { initFromLocalStorage, fillUserInfo } from './store/user';
import { fillTeamInfo } from './store/team';
import { fillAdminInfo } from './store/admin';
import { fetchUserInfoRequest, fetchTeamInfoRequest, fetchAdminInfo } from './profile/rest';
import { fetchNoticeRequest } from './notice/rest';
import { setNotice, setStatus } from './store/notice';
import { clear, setError } from './store/toast';

const mapStateToProps = (state) => ({
  toast: state.toast,
});

class App extends React.Component {
  componentDidMount() {
    store.dispatch(setStatus('fetching'));
    fetchNoticeRequest()
      .then((info) => {
        store.dispatch(setNotice(info));
      })
      .catch((error) => {
        store.dispatch(setError(error.message));
      })
      .then(() => {
        store.dispatch(setStatus('filled'));
      });
    subscribe('user.token', (state) => {
      const { status, token } = state.user;
      if (status === 'detached') {
        fetchUserInfoRequest(token)
          .then((info) => {
            store.dispatch(fillUserInfo(info));
            if (info?.is_admin) {
              fetchAdminInfo(token)
                .then((adminInfo) => {
                  store.dispatch(fillAdminInfo(adminInfo));
                })
                .catch((error) => {
                  store.dispatch(setError(error.message));
                });
            }
          })
          .catch((error) => {
            store.dispatch(setError(error.message));
          });
        fetchTeamInfoRequest(token)
          .then((info) => {
            store.dispatch(fillTeamInfo(info));
          })
          .catch((error) => {
            store.dispatch(setError(error.message));
          });
      }
    });
    store.dispatch(initFromLocalStorage());
  }

  render() {
    const { toast: { open, severity, message } } = this.props;

    return (
      <Router>
        <div>
          <TopBar />
          <Main />
          <Snackbar
            open={open}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            autoHideDuration={3000}
            onClose={() => store.dispatch(clear())}
          >
            <Alert severity={severity} sx={{ width: '100%' }}>
              {message}
            </Alert>
          </Snackbar>
        </div>
      </Router>
    );
  }
}

App.propTypes = {
  toast: PropTypes.shape({
    open: PropTypes.bool,
    severity: PropTypes.string,
    message: PropTypes.string,
  }),
};

App.defaultProps = {
  toast: store.getState().toast,
};

export default connect(mapStateToProps)(App);
