import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Stack,
  TextField,
} from '@mui/material';
import {
  LoadingButton,
} from '@mui/lab';
import {
  useHistory,
} from 'react-router-dom';
import cover from '../image/cover.login.png';
import { signInRequest } from './rest';
import store from '../store';
import { setSuccess, setError } from '../store/toast';
import { setToken } from '../store/user';
import { validateEmail, validatePassword } from '../validate';

export default function SignIn() {
  const [form, setForm] = useState({
    email: '',
    password: '',
    submitting: false,
  });

  const emailValidationMessage = () => validateEmail(form.email);
  const passwordValidationMessage = () => validatePassword(form.password);
  const validated = () => emailValidationMessage().length === 0
    && passwordValidationMessage().length === 0;

  const history = useHistory();

  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false; };
  }, []);

  return (
    <Stack direction="row" alignItems="center" justifyContent="center">
      <Card
        sx={{
          width: 350,
          mt: 5,
        }}
      >
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={2}
          sx={{ flexGrow: 1 }}
        >
          <CardMedia
            component="img"
            image={cover}
          />
        </Stack>
        <CardContent>
          <Stack direction="column" spacing={1}>
            <TextField
              id="email"
              label="邮箱"
              variant="standard"
              value={form.email}
              onChange={(event) => setForm({ ...form, email: event.target.value })}
              helperText={emailValidationMessage()}
              error={(emailValidationMessage().length !== 0) && (form.email.length !== 0)}
            />
            <TextField
              id="password"
              label="密码"
              variant="standard"
              value={form.password}
              type="password"
              onChange={(event) => setForm({ ...form, password: event.target.value })}
              helperText={passwordValidationMessage()}
              error={(passwordValidationMessage().length !== 0) && (form.password.length !== 0)}
            />
          </Stack>
        </CardContent>
        <CardActions>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            width="100%"
            spacing={1}
          >
            <LoadingButton
              disabled={!validated()}
              loading={form.submitting}
              onClick={() => {
                setForm({ ...form, submitting: true });
                signInRequest(form.email, form.password)
                  .then((json) => {
                    store.dispatch(setSuccess('登录成功'));
                    store.dispatch(setToken({ token: json.jwt_token }));
                    history.push('/profile');
                  })
                  .catch((error) => {
                    store.dispatch(setError(error.message));
                  })
                  .finally(() => {
                    // check if this component is still
                    // mounted to avoid mem leak
                    if (isMounted.current) {
                      setForm({ ...form, submitting: false });
                    }
                  });
              }}
            >
              登录
            </LoadingButton>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              sx={{
                color: 'secondary.main',
              }}
              onClick={() => {
                history.push('/sign-up');
              }}
            >
              注册
            </Button>
            <Button
              sx={{
                color: 'secondary.main',
              }}
              onClick={() => {
                history.push('/reset-password');
              }}
            >
              找回密码
            </Button>
          </Stack>
        </CardActions>
      </Card>
    </Stack>
  );
}
