import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import qs from 'qs';
import config from '../config.json';

axios.defaults.baseURL = config.baseURL;

export const uploadUserResume = async (token, resumeFile) => {
  const formData = new FormData();
  formData.append('resume', resumeFile);
  return axios.post('/user/update_resume', formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .catch((error) => {
      if (error.response) {
        throw Error('上传失败');
      } else {
        throw Error(error.response.error_info);
      }
    });
};

export const downloadResume = async (token) => axios.get('/user/download_resume', {
  responseType: 'blob',
  headers: {
    Authorization: `Bearer ${token}`,
  },
}).catch((error) => {
  if (error.request) {
    throw Error('网络连接错误');
  } else if (error.response) {
    throw Error('下载失败');
  }
  throw Error('未知错误');
})
  .then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'resume.pdf');
    document.body.appendChild(link);
    link.click();
  });

export const uploadUserSignature = async (token, signatureFile) => {
  const formData = new FormData();
  formData.append('signature', signatureFile);
  return axios.post('/user/update_signature', formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .catch((error) => {
      if (error.response) {
        throw Error('上传失败');
      } else {
        throw Error(error.response.error_info);
      }
    });
};

export const downloadSignature = async (token) => axios.get('/user/download_signature', {
  responseType: 'blob',
  headers: {
    Authorization: `Bearer ${token}`,
  },
}).catch((error) => {
  if (error.request) {
    throw Error('网络连接错误');
  } else if (error.response) {
    throw Error('下载失败');
  }
  throw Error('未知错误');
})
  .then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'signature.png');
    document.body.appendChild(link);
    link.click();
  });

export const fetchUserInfoRequest = async (token) => {
  if (config.mocking.includes('get-user-info')) {
    const mock = new MockAdapter(axios);
    mock.onGet('/user/info')
      .reply(async () => {
        await new Promise((resolve) => setTimeout(resolve, 500));
        return [200, JSON.stringify({
          uid: 0,
          email: 'cydiater@gmail.com',
          username: 'cydiater',
          usertype: 'a',
          invitation: [
            {
              teamname: '焦景辉的队伍',
              teamid: 123,
            },
            {
              teamname: '焦景辉的队伍',
              teamid: 123,
            },
          ],
        })];
      });
  }
  return axios.get('/user/info', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .catch((error) => {
      if (error.response) {
        throw Error('无法获取用户信息，请重新登录');
      } else if (error.request) {
        throw Error('网络连接错误');
      }
      throw Error('未知错误');
    })
    .then((response) => response.data);
};

export const updateUserInfoRequest = async (info, token) => {
  if (config.mocking.includes('update-user-info')) {
    const mock = new MockAdapter(axios);
    mock.onPost('/user/update_info')
      .reply(async () => {
        await new Promise((resolve) => setTimeout(resolve, 500));
        return [200];
      });
  }
  return axios.post('/user/update_info', qs.stringify({
    username: info.username,
    gender: info.gender,
    name: info.name,
    school: info.school,
    speciality: info.speciality,
    phone: info.phone,
    wechat: info.wechat,
    qq: info.qq,
    oiicpc: info.oiicpc,
    codeforces: info.codeforces,
    graduate_time: info.graduate_time,
    grade: info.grade,
    usertype: info.usertype,
    clothingSize: info.clothingSize,
    address: info.address,
    wishes: info.wishes,
    id_card: info.id_card,
    workplace: info.workplace,
    location: info.location,
  }), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .catch((error) => {
      if (error.response) {
        throw Error('无法更新个人信息，请尝试重新登录');
      } else if (error.request) {
        throw Error('网络连接错误');
      }
      throw Error('未知错误');
    });
};

export const fetchTeamInfoRequest = async (token) => {
  if (config.mocking.includes('team-info')) {
    const mock = new MockAdapter(axios);
    mock.onGet('/team/info')
      .reply(async () => {
        await new Promise((resolve) => setTimeout(resolve, 500));
        return [200, {
          teamname: '焦景辉的队伍',
          tid: 12,
          teamtype: 'A',
          num_member: 2,
          is_leader: true,
          member_list: [
            {
              email: 'cydiater@gmail.com',
              username: 'cydiater',
              usertype: 'a',
              school: '清华大学',
              name: '焦景辉',
              gender: 'M',
            },
            {
              email: 'cydiater@gmail.com',
              username: 'cydiater',
              usertype: 'a',
              school: '清华大学',
              name: '焦景辉',
              gender: 'M',
            },
          ],
          invitation: [
            'cydiater@gmail.com',
          ],
        }];
      });
  }
  return axios.get('/team/info', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          return {};
        }
        throw Error('无法获取队伍信息，请重新登录');
      } else if (error.request) {
        throw Error('网络连接错误');
      }
      throw Error('未知错误');
    })
    .then((response) => response.data);
};

export const updateTeamNameRequest = async (token, teamName) => {
  if (config.mocking.includes('update-teamname')) {
    const mock = new MockAdapter(axios);
    mock.onPost('/team/update_teamname')
      .reply(async () => {
        await new Promise((resolve) => setTimeout(resolve, 500));
        return [200];
      });
  }
  return axios.post('/team/update_name', qs.stringify({
    teamname: teamName,
  }), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .catch((error) => {
      if (error.request) {
        throw Error('网络连接错误');
      } else if (error.response) {
        throw Error('无法修改队伍名称，请重新登录');
      }
      throw Error('未知错误');
    });
};

export const newInvitationRequest = async (token, tid, email) => {
  if (config.mocking.includes('new-invitation')) {
    const mock = new MockAdapter(axios);
    mock.onPost('/team/update')
      .reply(async () => {
        await new Promise((resolve) => setTimeout(resolve, 500));
        return [200];
      });
  }
  return axios.post('/team/update', qs.stringify({
    tid,
    email,
    op: 1,
  }), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          throw Error(error.response.data.error_info);
        } else if (error.request) {
          throw Error('网络连接错误');
        }
        throw Error('未知错误');
      }
    });
};

export const removeInvitationRequest = async (token, tid, email) => {
  if (config.mocking.includes('abort-invitation')) {
    const mock = new MockAdapter(axios);
    mock.onPost('/team/update')
      .reply(async () => {
        await new Promise((resolve) => setTimeout(resolve, 500));
        return [200];
      });
  }
  return axios.post('/team/update', qs.stringify({
    email,
    op: 0,
    tid,
  }), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .catch((error) => {
      if (error.request) {
        throw Error('网络连接错误');
      } else if (error.response) {
        throw Error('无法撤回邀请，请重新登录');
      }
      throw Error('未知错误');
    })
    .then((response) => response.data);
};

export const removeTeamMemberRequest = async (token, tid, email) => {
  if (config.mocking.includes('remove-team-member')) {
    const mock = new MockAdapter(axios);
    mock.onPost('/team/update')
      .reply(async () => {
        await new Promise((resolve) => setTimeout(resolve, 500));
        return [200];
      });
  }
  return axios.post('/team/update', qs.stringify({
    tid,
    email,
    op: 0,
  }), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .catch((error) => {
      if (error.response) {
        throw Error('移除失败，请尝试重新登录');
      } else if (error.request) {
        throw Error('网络连接错误');
      }
      throw Error('未知错误');
    });
};

export const acceptInvitationRequest = async (token, tid, email) => {
  if (config.mocking.includes('accept-invitation')) {
    const mock = new MockAdapter(axios);
    mock.onPost('/team/update')
      .reply(async () => {
        await new Promise((resolve) => setTimeout(resolve, 500));
        return [200];
      });
  }
  return axios.post('/team/update', qs.stringify({
    email,
    tid,
    op: 2,
  }), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      if (error.request) {
        throw Error('网络连接错误');
      } else if (error.response) {
        throw Error('无法接收邀请，请重新登录');
      }
      throw Error('未知错误');
    });
};

export const updateConfirmationRequest = async (token, confirmation) => axios.post('/user/confirm', qs.stringify({ confirm: confirmation }), {
  headers: {
    Authorization: `Bearer ${token}`,
  },
})
  .then((response) => response.data)
  .catch((error) => {
    if (error.request) {
      throw Error(error.response.data.error_info);
    } else if (error.response) {
      throw Error('无法确认比赛，请重新登录');
    }
    throw Error('未知错误');
  });

export const createTeamRequest = async (token, teamname) => {
  if (config.mocking.includes('create-team')) {
    const mock = new MockAdapter(axios);
    mock.onPost('/team/create')
      .reply(async () => {
        await new Promise((resolve) => setTimeout(resolve, 500));
        return [200];
      });
  }
  return axios.post('/team/create', qs.stringify({
    teamname,
  }), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fetchAdminInfo = async (token) => {
  if (config.mocking.includes('admin-info')) {
    const mock = new MockAdapter(axios);
    mock.onGet('/team/get_global_info')
      .reply(async () => {
        await new Promise((resolve) => setTimeout(resolve, 500));
        return [200, {
          all_user_num: 1,
          all_team_num: 2,
          verify_user_num: 3,
          verify_team_num: 4,
        }];
      });
  }
  return axios.get('/team/get_global_info', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          return {};
        }
        throw Error('无法获取信息，请重新登录');
      } else if (error.request) {
        throw Error('网络连接错误');
      }
      throw Error('未知错误');
    })
    .then((response) => response.data);
};

export default {
  fetchUserInfoRequest,
  fetchTeamInfoRequest,
  updateUserInfoRequest,
  updateTeamNameRequest,
  newInvitationRequest,
  acceptInvitationRequest,
  removeInvitationRequest,
  removeTeamMemberRequest,
  createTeamRequest,
  uploadUserResume,
  downloadResume,
  uploadUserSignature,
  downloadSignature,
  updateConfirmationRequest,
  fetchAdminInfo,
};
