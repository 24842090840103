import React from 'react';
import {
  Link as ReactLink,
  useHistory,
} from 'react-router-dom';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import LogoutIcon from '@mui/icons-material/Logout';
import { useSelector } from 'react-redux';
import {
  Stack,
  AppBar,
  Box,
  Toolbar,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';
import store from './store';
import { clearToken } from './store/user';
import { clearTeamInfo } from './store/team';

export default function TopBar() {
  const status = useSelector((state) => state.user.status);
  const history = useHistory();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar variant="dense">

          <Button component={ReactLink} to="/">
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
              <AnnouncementIcon
                sx={{
                  color: 'common.white',
                }}
              />
              <Typography variant="h6" color="common.white">
                公告
              </Typography>
            </Stack>
          </Button>

          <Box sx={{ flexGrow: 1 }} />

          {status === 'empty' && (
            <Button component={ReactLink} to="/sign-in">
              <Typography variant="h6" color="common.white">
                登录
              </Typography>
            </Button>
          )}

          {status === 'empty' && (
            <Button component={ReactLink} to="/sign-up">
              <Typography variant="h6" color="common.white">
                注册
              </Typography>
            </Button>
          )}

          {status === 'detached' && (
            <CircularProgress
              size={30}
              sx={{
                color: 'common.white',
              }}
            />
          )}

          {status === 'filled' && (
            <Button component={ReactLink} to="/profile">
              <Typography variant="h6" color="common.white">
                报名信息
              </Typography>
            </Button>
          )}

          {(status === 'filled' || status === 'detached') && (
            <Button onClick={() => {
              store.dispatch(clearToken());
              store.dispatch(clearTeamInfo());
              history.push('/user/sign-in');
            }}
            >
              <LogoutIcon
                sx={{
                  color: 'common.white',
                }}
              />
            </Button>
          )}

        </Toolbar>
      </AppBar>
    </Box>
  );
}
