import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Stack,
  Paper,
  Tab,
  Alert,
} from '@mui/material';
import {
  TabContext,
  TabList,
  TabPanel,
} from '@mui/lab';
import IndividualProfile from './individual';
import TeamProfile from './team';
import AdminProfile from './admin';

function Profile() {
  const [currentTab, setTab] = useState('individual');
  const haveUserInfo = useSelector((state) => {
    if (state.user.info.username !== undefined && state.user.info.username.length === 0) {
      return false;
    }
    if (state.user.info.gender !== undefined && state.user.info.gender.length === 0) {
      return false;
    }
    if (state.user.info.school !== undefined && state.user.info.school.length === 0) {
      return false;
    }
    if (state.user.info.usertype !== undefined && state.user.info.usertype.length === 0) {
      return false;
    }
    if (state.user.info.name !== undefined && state.user.info.name.length === 0) {
      return false;
    }
    // if (state.user.info.location !== undefined && state.user.info.location.length === 0) {
    //   return false;
    // }
    if (state.user.info.graduate_time === 0) {
      return false;
    }
    if (state.user.info.phone !== undefined && state.user.info.phone.length === 0) {
      return false;
    }
    if (state.user.info.grade !== undefined && state.user.info.grade.length === 0) {
      return false;
    }
    if (state.user.info.wechat !== undefined && state.user.info.wechat.length === 0) {
      return false;
    }
    return true;
  });

  const haveTeamInfo = useSelector((state) => state.team.tid !== -1);
  const confirmContest = useSelector((state) => state.team.confirm === 1);
  //   const qualification = useSelector((state) => state.team.qualification === 1);
  const isAdmin = useSelector((state) => state.user.info.is_admin);

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={2}
      sx={{
        mt: 3,
      }}
    >
      {!haveUserInfo && (
        <Alert
          sx={{
            width: 600,
          }}
          severity="error"
        >
          尚未完善个人信息
        </Alert>
      )}

      {!haveTeamInfo && (
      <Alert
        sx={{
          width: 600,
        }}
        severity="error"
      >
        尚未加入任何队伍
      </Alert>
      )}

      {/* {haveUserInfo && haveTeamInfo && confirmFinal && qualification && ( */}
      {haveUserInfo && haveTeamInfo && confirmContest && (
        <Alert
          sx={{
            width: 600,
          }}
          severity="success"
        >
          确认参加 THUPC2025（队长可在队伍信息页面撤销参赛）
        </Alert>
      )}

      {/* {haveUserInfo && haveTeamInfo && !confirmFinal && qualification && ( */}
      {haveUserInfo && haveTeamInfo && !confirmContest && (
        <Alert
          sx={{
            width: 600,
          }}
          severity="warning"
        >
          未确认参加 THUPC2025（队长可在队伍信息页面确认参赛）
        </Alert>
      )}

      {/* {haveUserInfo && haveTeamInfo && !qualification && (
        <Alert
          sx={{
            width: 600,
          }}
          severity="warning"
        >
          队伍未进入复赛
        </Alert>
      )} */}

      <Paper
        sx={{
          width: 550,
          p: 1,
        }}
      >
        <TabContext value={currentTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={(event, newValue) => setTab(newValue)}
            >
              <Tab label="个人信息" value="individual" />
              <Tab label="队伍信息" value="team" />
              {isAdmin && (
                <Tab label="后台管理" value="admin" />
              )}
            </TabList>
          </Box>
          <TabPanel
            sx={{
              p: 1,
              pb: 0,
            }}
            value="individual"
          >
            <IndividualProfile />
          </TabPanel>
          <TabPanel
            sx={{
              p: 1,
              pb: 0,
            }}
            value="team"
          >
            <TeamProfile />
          </TabPanel>
          <TabPanel
            sx={{
              p: 1,
              pb: 0,
            }}
            value="admin"
          >
            <AdminProfile />
          </TabPanel>
        </TabContext>
      </Paper>
    </Stack>
  );
}

export default Profile;
