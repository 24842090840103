import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  useParams,
} from 'react-router-dom';
import {
  Box,
  Card,
  CardHeader,
  Stack,
  TextField,
} from '@mui/material';
import {
  LoadingButton,
} from '@mui/lab';
import MDEditor from '@uiw/react-md-editor';
import {
  setSuccess,
  setError,
} from '../store/toast';
import store from '../store';
import {
  setNotice,
} from '../store/notice';
import {
  publishNoticeRequest,
  fetchNoticeRequest,
  updateNoticeRequest,
} from './rest';

export default function NoticeEdit() {
  const { id } = useParams();
  const n = useSelector((state) => state.notice.noticeList.find((o) => o.id === parseInt(id, 10)));

  const [form, setForm] = useState({
    title: n?.title ?? '',
    text: n?.content ?? '',
  });

  useEffect(() => {
    setForm({
      title: n?.title ?? '',
      text: n?.content ?? '',
    });
  }, [n]);

  const token = useSelector((state) => state.user.token);
  const [submitting, setSubmitting] = useState(false);

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        mt: 2,
      }}
    >
      <Card
        sx={{
          width: 800,
          p: 2,
          m: 2,
        }}
      >
        {n === undefined && (
          <CardHeader
            title="发布公告"
            action={(
              <LoadingButton
                disabled={(form.text.length === 0) || (form.title.length === 0)}
                loading={submitting}
                onClick={() => {
                  setSubmitting(true);
                  publishNoticeRequest(form.title, form.text, token)
                    .then(() => fetchNoticeRequest())
                    .then((noticeList) => {
                      store.dispatch(setNotice(noticeList));
                      store.dispatch(setSuccess('发布成功'));
                    })
                    .catch((error) => {
                      store.dispatch(setError(error.message));
                    })
                    .then(() => {
                      setSubmitting(false);
                    });
                }}
              >
                发布公告
              </LoadingButton>
            )}
          />
        )}

        {n !== undefined && (
          <CardHeader
            title="编辑公告"
            action={(
              <LoadingButton
                disabled={(form.text.length === 0) || (form.title.length === 0)}
                loading={submitting}
                onClick={() => {
                  setSubmitting(true);
                  updateNoticeRequest(id, form.title, form.text, token)
                    .then(() => fetchNoticeRequest())
                    .then((noticeList) => {
                      store.dispatch(setNotice(noticeList));
                      store.dispatch(setSuccess('编辑成功'));
                    })
                    .catch((error) => {
                      store.dispatch(setError(error.message));
                    })
                    .then(() => {
                      setSubmitting(false);
                    });
                }}
              >
                编辑公告
              </LoadingButton>
            )}
          />
        )}
        <Stack
          direction="row"
          spacing={2}
          sx={{
            p: 1,
          }}
        >
          <Stack
            direction="column"
            spacing={2}
            sx={{
              width: 400,
            }}
          >
            <TextField
              variant="standard"
              id="title"
              label="公告标题"
              value={form.title}
              onChange={(event) => setForm({ ...form, title: event.target.value })}
            />
            <TextField
              id="editor"
              label="公告内容"
              multiline
              value={form.text}
              onChange={(event) => setForm({ ...form, text: event.target.value })}
            />
          </Stack>
          <Box
            data-color-mode="light"
            sx={{
              width: 400,
            }}
          >
            <MDEditor.Markdown
              highlightEnable
              source={form.text}
            />
          </Box>
        </Stack>
      </Card>
    </Stack>
  );
}
