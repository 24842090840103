import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import MDEditor from '@uiw/react-md-editor';
import {
  Button,
  Card,
  Collapse,
  CardActions,
  CardContent,
  IconButton,
  Typography,
} from '@mui/material';
import {
  LoadingButton,
} from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  useHistory,
} from 'react-router-dom';
import store from '../store';
import {
  setSuccess,
  setError,
} from '../store/toast';
import {
  setNotice,
} from '../store/notice';
import {
  deleteNoticeRequest,
  fetchNoticeRequest,
} from './rest';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function Entry(props) {
  const {
    title,
    timestamp,
    content,
    id,
  } = props;
  const [expanded, setExpanded] = useState(false);
  const dateObj = new Date(timestamp);
  const showDate = dateObj.toDateString();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const history = useHistory();

  const isAdmin = useSelector((state) => state.user.info.is_admin);
  const token = useSelector((state) => state.user.token);
  const [removingNotice, setRemovingNotice] = useState(false);
  const removeNotice = () => {
    setRemovingNotice(true);
    deleteNoticeRequest(id, token)
      .then(() => fetchNoticeRequest())
      .then((noticeList) => {
        store.dispatch(setNotice(noticeList));
        store.dispatch(setSuccess('删除成功'));
      })
      .catch((error) => {
        store.dispatch(setError(error.message));
      })
      .then(() => {
        setRemovingNotice(false);
      });
  };

  return (
    <Card
      sx={{
        mb: 2,
        width: 650,
      }}
    >
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {showDate}
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        {isAdmin && (
          <>
            <Button
              onClick={() => {
                history.push(`/notice-edit/${id}`);
              }}
            >
              编辑公告
            </Button>
            <LoadingButton
              loading={removingNotice}
              onClick={removeNotice}
            >
              删除公告
            </LoadingButton>
          </>
        )}
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent
          data-color-mode="light"
        >
          <MDEditor.Markdown
            source={content}
          />
        </CardContent>
      </Collapse>
    </Card>
  );
}

Entry.propTypes = {
  title: PropTypes.string,
  timestamp: PropTypes.string,
  content: PropTypes.string,
  id: PropTypes.number,
};

Entry.defaultProps = {
  title: '公告标题',
  timestamp: '2021/11/6',
  content: '公告内容',
  id: 0,
};
