import { configureStore } from '@reduxjs/toolkit';
import initSubscriber from 'redux-subscriber';
import userReducer from './user';
import toastReducer from './toast';
import teamReducer from './team';
import noticeReducer from './notice';
import adminReducer from './admin';

const store = configureStore({
  reducer: {
    user: userReducer,
    toast: toastReducer,
    team: teamReducer,
    notice: noticeReducer,
    admin: adminReducer,
  },
});

const subscribe = initSubscriber(store);

export { subscribe };
export default store;
