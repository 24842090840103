import { createSlice } from '@reduxjs/toolkit';

const TeamSlice = createSlice({
  name: 'team',
  initialState: {
    status: 'detached',
    teamname: '',
    tid: -1,
    teamtype: 'A',
    num_member: 0,
    is_leader: false,
    member_list: [],
    invitations: [],
    confirm: 0,
    freezed: false,
  },
  reducers: {
    setTeamname(state, action) {
      state.teamname = action.payload;
    },
    setDetached(state) {
      state.status = 'detached';
    },
    removeMember(state, action) {
      state.member_list.splice(action.payload, 1);
    },
    newInvitation(state, action) {
      state.invitation.push(action.payload);
    },
    removeInvitation(state, action) {
      state.invitation.splice(action.payload, 1);
    },
    fillTeamInfo(state, action) {
      state.status = 'filled';
      const {
        teamname,
        tid,
        teamtype,
        num_member: numMember,
        is_leader: isLeader,
        member_list: memberList,
        invitation,
        oj_username: ojUsername,
        oj_password: ojPassword,
        confirm,
        qualification,
      } = {
        ...state,
        ...action.payload,
      };
      state.teamname = teamname;
      state.tid = tid;
      state.teamtype = teamtype;
      state.num_member = numMember;
      state.is_leader = isLeader;
      state.member_list = memberList;
      state.invitation = invitation;
      state.ojUsername = ojUsername;
      state.ojPassword = ojPassword;
      state.confirm = confirm;
      state.qualification = qualification;
    },
    clearTeamInfo(state) {
      state.tid = -1;
    },
  },
});

export const {
  setTeamname,
  setDetached,
  fillTeamInfo,
  removeMember,
  newInvitation,
  removeInvitation,
  clearTeamInfo,
} = TeamSlice.actions;

export default TeamSlice.reducer;
