import React, { useState } from 'react';
import {
  Stack,
  CircularProgress,
  Typography,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  ListItemIcon,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {
  LoadingButton,
} from '@mui/lab';
import { useSelector } from 'react-redux';
import {
  acceptInvitationRequest,
  createTeamRequest,
  fetchTeamInfoRequest,
  fetchUserInfoRequest,
  updateTeamNameRequest,
  newInvitationRequest,
  removeInvitationRequest,
  removeTeamMemberRequest,
  updateConfirmationRequest,
} from './rest';
import {
  validateEmail,
} from '../validate';
import store from '../store';
import {
  setError,
  setSuccess,
} from '../store/toast';
import {
  clearTeamInfo,
  fillTeamInfo,
  setTeamname,
  newInvitation,
  removeInvitation,
} from '../store/team';
import {
  fillUserInfo,
} from '../store/user';

function TeamProfile() {
  const token = useSelector((state) => state.user.token);
  const status = useSelector((state) => state.team.status);
  const haveTeam = useSelector((state) => state.team.tid !== -1);
  const team = useSelector((state) => state.team);
  const user = useSelector((state) => state.user);

  const [updatingTeamName, setUpdatingTeamName] = useState(false);
  const [updatingConfirmation, setUpdatingConfirmation] = useState(false);
  const [sendingInvitation, setSendingInvitation] = useState(false);
  const [creatingTeam, setCreatingTeam] = useState(false);
  const [newInvitationEmail, setNewInvitationEmail] = useState('');
  const [removingInvitationIndex, setRemovingInvitationIndex] = useState(-1);
  const [removingTeamMemberIndex, setRemovingTeamMemberIndex] = useState(-1);
  const [acceptingInvitationIndex, setAcceptingInvitationIndex] = useState(-1);
  const [removingTeam, setRemovingTeam] = useState(false);
  const [fetchingTeamInfo, setFetchingTeamInfo] = useState(false);

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        mt: 1,
      }}
    >
      {status === 'detached' && (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Stack>
      )}

      {status === 'filled' && (
        !haveTeam && (
          <Stack
            direction="column"
            spacing={2}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Typography>
                尚不属于任何队伍，你可以选择接收其他队伍的邀请或者
              </Typography>
              {/* <Typography>
                尚不属于任何队伍，现阶段比赛无法组队
              </Typography> */}
              <LoadingButton
                sx={{
                  flexGrow: 1,
                }}
                loading={creatingTeam}
                onClick={() => {
                  setCreatingTeam(true);
                  createTeamRequest(token, `${user.info.username}的队伍`)
                    .then(() => fetchTeamInfoRequest(token))
                    .then((info) => {
                      store.dispatch(setSuccess('创建成功'));
                      store.dispatch(fillTeamInfo(info));
                      setCreatingTeam(false);
                    })
                    .catch((error) => {
                      setCreatingTeam(false);
                      store.dispatch(setError(error.message));
                    });
                }}
              >
                创建队伍
              </LoadingButton>
            </Stack>
            <List
              subheader={(
                <ListSubheader
                  component="div"
                  id="teammember"
                  sx={{
                    p: 0,
                  }}
                >
                  收到的邀请
                </ListSubheader>
              )}
              dense
            >
              {
                user.info.invitation.map((invitation, index) => (
                  <ListItem
                    secondaryAction={(
                      <LoadingButton
                        disabled={
                          acceptingInvitationIndex !== -1
                            && index !== acceptingInvitationIndex
                        }
                        loading={index === acceptingInvitationIndex}
                        onClick={() => {
                          setAcceptingInvitationIndex(index);
                          acceptInvitationRequest(token, invitation.teamid, user.info.email)
                            .then(() => fetchTeamInfoRequest(token))
                            .then((info) => {
                              setAcceptingInvitationIndex(-1);
                              store.dispatch(setSuccess('成功加入队伍'));
                              store.dispatch(fillTeamInfo(info));
                            })
                            .catch((error) => {
                              setAcceptingInvitationIndex(-1);
                              store.dispatch(setError(error.message));
                            });
                        }}
                      >
                        接受邀请
                      </LoadingButton>
                    )}
                    disablePadding
                  >
                    <ListItemIcon>
                      <PersonAddIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={invitation.teamname}
                    />
                  </ListItem>
                ))
              }
            </List>

            <Stack
              direction="row"
            >
              <LoadingButton
                loading={fetchingTeamInfo}
                onClick={() => {
                  setFetchingTeamInfo(true);
                  fetchUserInfoRequest(token)
                    .then((info) => {
                      store.dispatch(fillUserInfo(info));
                      store.dispatch(setSuccess('刷新邀请成功'));
                    })
                    .catch((error) => {
                      store.dispatch(setError(error.message));
                    })
                    .then(() => {
                      setFetchingTeamInfo(false);
                    });
                }}
              >
                刷新邀请
              </LoadingButton>
            </Stack>

          </Stack>
        )
      )}

      {status === 'filled' && (
        haveTeam && (
          <Stack
            direction="column"
            spacing={2}
          >
            <Stack
              direction="row"
              spacing={2}
            >
              <TextField
                disabled={!team.is_leader}
                variant="standard"
                label="队伍名称"
                value={team.teamname}
                onChange={(event) => store.dispatch(setTeamname(event.target.value))}
                sx={{
                  flexGrow: 1,
                }}
              />
              <TextField
                disabled
                variant="standard"
                label="比赛密码"
                value={team.ojPassword}
              />
              <TextField
                disabled
                variant="standard"
                label="比赛用户名"
                value={team.ojUsername}
              />
            </Stack>

            <FormControl
              variant="standard"
            >
              <InputLabel id="type-label">队伍类型</InputLabel>
              <Select
                id="type"
                label="队伍类型"
                labelId="type-label"
                value={team.teamtype}
                disabled
              >
                <MenuItem value="A">目前就读于清华大学</MenuItem>
                <MenuItem value="B">目前就读于其他大学</MenuItem>
                <MenuItem value="C">不是在读大学生</MenuItem>
              </Select>
            </FormControl>

            <List
              subheader={(
                <ListSubheader
                  component="div"
                  id="teammember"
                  sx={{
                    p: 0,
                  }}
                >
                  队员信息
                </ListSubheader>
              )}
              dense
            >
              {
                team.member_list.map((memberInfo, index) => (
                // team.member_list.map((memberInfo) => (
                  <ListItem
                    key={memberInfo.email}
                    secondaryAction={team.is_leader
                        && !(memberInfo.email === user.info.email && team.is_leader)
                        && (
                          <LoadingButton
                            color="warning"
                            disabled={
                              removingTeamMemberIndex !== -1
                                && removingTeamMemberIndex !== index
                            }
                            loading={removingTeamMemberIndex === index}
                            onClick={() => {
                              setRemovingTeamMemberIndex(index);
                              removeTeamMemberRequest(token, team.tid, memberInfo.email)
                                .then(() => fetchTeamInfoRequest(token))
                                .then((info) => {
                                  setRemovingTeamMemberIndex(-1);
                                  store.dispatch(setSuccess('成功移出队伍'));
                                  store.dispatch(fillTeamInfo(info));
                                })
                                .catch((error) => {
                                  setRemovingTeamMemberIndex(-1);
                                  store.dispatch(setError(error.message));
                                });
                            }}
                          >
                            移出队伍
                          </LoadingButton>
                        )}
                    disablePadding
                  >
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={memberInfo.username}
                      secondary={memberInfo.email}
                    />
                  </ListItem>
                ))
              }
            </List>

            {(team.is_leader && (
            <Stack direction="column">
              <Stack
                direction="row"
                spacing={2}
              >
                <TextField
                  label="邀请队员"
                  variant="standard"
                  error={validateEmail(newInvitationEmail).length !== 0}
                  helperText={
                    validateEmail(newInvitationEmail).length !== 0
                      ? validateEmail(newInvitationEmail) : '邀请队员时请填写对方的注册邮箱'
                  }
                  sx={{
                    flexGrow: 1,
                  }}
                  onChange={(event) => setNewInvitationEmail(event.target.value)}
                  value={newInvitationEmail}
                />
                <LoadingButton
                  disabled={team.freezed || validateEmail(newInvitationEmail).length !== 0}
                  loading={sendingInvitation}
                  onClick={() => {
                    setSendingInvitation(true);
                    newInvitationRequest(token, team.tid, newInvitationEmail)
                      .then(() => {
                        setSendingInvitation(false);
                        store.dispatch(newInvitation(newInvitationEmail));
                        store.dispatch(setSuccess('邀请成功'));
                        setNewInvitationEmail('');
                      })
                      .catch((error) => {
                        store.dispatch(setError(error.message));
                        setSendingInvitation(false);
                      });
                  }}
                >
                  发送
                </LoadingButton>
              </Stack>
              {team.freezed && (
                <Typography
                  variant="caption"
                  color="text.secondary"
                >
                  初赛已结束，无法发送邀请
                </Typography>
              )}
            </Stack>
            ))}

            {(team.is_leader && (

              <List
                subheader={(
                  <ListSubheader
                    component="div"
                    id="teammember"
                    sx={{
                      p: 0,
                    }}
                  >
                    已发送的邀请
                  </ListSubheader>
                )}
                dense
              >
                {
                  team.invitation.map((email, index) => (
                    <ListItem
                      key={email}
                      secondaryAction={(
                        <LoadingButton
                          disable={removingInvitationIndex !== -1}
                          loading={removingInvitationIndex === index}
                          color="warning"
                          onClick={() => {
                            setRemovingInvitationIndex(index);
                            removeInvitationRequest(token, team.tid, email)
                              .then(() => {
                                setRemovingInvitationIndex(-1);
                                store.dispatch(removeInvitation(index));
                                store.dispatch(setSuccess('撤回成功'));
                              })
                              .catch((error) => {
                                setRemovingInvitationIndex(-1);
                                store.dispatch(setError(error.message));
                              });
                          }}
                        >
                          撤回邀请
                        </LoadingButton>
                      )}
                      disablePadding
                    >
                      <ListItemIcon>
                        <PersonAddIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={email}
                      />
                    </ListItem>
                  ))
                }
              </List>
            ))}

            <Typography
              variant="caption"
              color="text.secondary"
            >
              你需要先离开现在的队伍，才能加入到别人的队伍中
            </Typography>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={1}
              sx={{
                mt: 1,
              }}
            >
              {(team.is_leader && (team.teamname.length !== 0) && (
                <LoadingButton
                  loading={updatingTeamName}
                  onClick={() => {
                    setUpdatingTeamName(true);
                    updateTeamNameRequest(token, team.teamname)
                      .then(() => {
                        setUpdatingTeamName(false);
                        store.dispatch(setSuccess('更新成功'));
                      })
                      .catch((error) => {
                        setUpdatingTeamName(false);
                        store.dispatch(setError(error.message));
                      });
                  }}
                >
                  更新队伍名称
                </LoadingButton>
              ))}

              {(team.is_leader && (
                <LoadingButton
                  loading={updatingConfirmation}
                  color={team.confirm === 0 ? 'primary' : 'warning'}
                  onClick={() => {
                    setUpdatingConfirmation(true);
                    updateConfirmationRequest(token, team.confirm === 0 ? 1 : 0)
                      .then(() => fetchTeamInfoRequest(token))
                      .then((info) => {
                        store.dispatch(fillTeamInfo(info));
                      })
                      .then(() => {
                        setUpdatingConfirmation(false);
                        store.dispatch(setSuccess('确认成功'));
                      })
                      .catch((error) => {
                        setUpdatingConfirmation(false);
                        store.dispatch(setError(error.message));
                      });
                  }}
                >
                  {/* {team.confirm === 0 ? '同意参加复赛' : '无法参加复赛'} */}
                  {team.confirm === 0 ? '确认参赛（目前状态为未确认参赛）' : '取消参赛（目前状态为确认参赛）'}
                </LoadingButton>
              ))}

              {(team.is_leader && (
                <LoadingButton
                  loading={removingTeam}
                  color="warning"
                  onClick={() => {
                    setRemovingTeam(true);
                    removeTeamMemberRequest(token, team.tid, user.info.email)
                      .then(() => {
                        store.dispatch(setSuccess('删除成功'));
                        store.dispatch(clearTeamInfo());
                      })
                      .catch((error) => {
                        store.dispatch(setError(error.message));
                      })
                      .finally(() => {
                        setRemovingTeam(false);
                      });
                  }}
                >
                  删除队伍
                </LoadingButton>
              ))}

              {(!team.is_leader && (
                <LoadingButton
                  loading={removingTeam}
                  color="warning"
                  onClick={() => {
                    setRemovingTeam(true);
                    removeTeamMemberRequest(token, team.tid, user.info.email)
                      .then(() => {
                        store.dispatch(setSuccess('删除成功'));
                        store.dispatch(clearTeamInfo());
                      })
                      .catch((error) => {
                        store.dispatch(setError(error.message));
                      })
                      .finally(() => {
                        setRemovingTeam(false);
                      });
                  }}
                >
                  退出队伍
                </LoadingButton>
              ))}
              <Box sx={{ flexGrow: 1 }} />
              <Chip
                color="success"
                label={team.is_leader ? '队长' : '队员'}
                variant="outlined"
              />
            </Stack>

          </Stack>
        )
      )}

    </Stack>
  );
}

export default TeamProfile;
