import React, { useState } from 'react';
import {
  Box,
  Chip,
  TextField,
  Stack,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
} from '@mui/material';
import {
  LoadingButton,
  DatePicker,
} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { useSelector } from 'react-redux';
import {
  updateUserInfoRequest,
  //   uploadUserResume,
  //   downloadResume,
  uploadUserSignature,
  downloadSignature,
  fetchUserInfoRequest,
} from './rest';
import store from '../store';
import { setError, setSuccess } from '../store/toast';
import { updateInfo, fillUserInfo } from '../store/user';

function IndividualProfile() {
  const info = useSelector((state) => state.user.info);
  const token = useSelector((state) => state.user.token);
  const status = useSelector((state) => state.user.status);
  const isAdmin = useSelector((state) => state.user.info.is_admin);
  //   const hasResume = useSelector((state) => state.user.info.has_resume);
  const hasSignature = useSelector((state) => state.user.info.has_signature);

  const [submitting, setSubmitting] = useState(false);
  //   const [downloadingResume, setDownloadingResume] = useState(false);
  //   const [uploadingResume, setUploadingResume] = useState(false);
  const [downloadingSignature, setDownloadingSignature] = useState(false);
  const [uploadingSignature, setUploadingSignature] = useState(false);

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        mt: 1,
      }}
    >
      {status !== 'filled' && (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Stack>
      )}

      {status === 'filled' && (
        <Stack
          direction="row"
          spacing={2}
        >
          <TextField
            id="email"
            label="邮箱"
            variant="standard"
            value={info.email}
            disabled
            sx={{
              flexGrow: 1,
            }}
          />
          <TextField
            id="username"
            label="用户名"
            variant="standard"
            value={info.username}
            onChange={(event) => store.dispatch(
              updateInfo({
                username: event.target.value,
              }),
            )}
          />
          <FormControl
            variant="standard"
          >
            <InputLabel id="gender-label" shrink>性别</InputLabel>
            <Select
              id="gender"
              label="性别"
              labelId="gender-label"
              value={info.gender}
              onChange={(event) => store.dispatch(
                updateInfo({
                  gender: event.target.value,
                }),
              )}
            >
              <MenuItem value="M">男</MenuItem>
              <MenuItem value="F">女</MenuItem>
              <MenuItem value="O">其他</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      )}

      {status === 'filled' && (
        <Stack direction="row" spacing={2}>
          <TextField
            id="name"
            label="真实姓名"
            variant="standard"
            value={info.name}
            onChange={(event) => store.dispatch(updateInfo({ name: event.target.value }))}
          />
          <TextField
            id="school"
            label="学校"
            variant="standard"
            value={info.school}
            onChange={(event) => store.dispatch(updateInfo({ school: event.target.value }))}
          />
          <TextField
            id="speciality"
            label="专业（选填）"
            variant="standard"
            value={info.speciality}
            onChange={(event) => store.dispatch(updateInfo({ speciality: event.target.value }))}
          />
        </Stack>
      )}

      {status === 'filled' && (
        <Stack direction="row" spacing={2}>
          <TextField
            id="phone"
            label="手机号"
            variant="standard"
            value={info.phone}
            onChange={(event) => store.dispatch(updateInfo({ phone: event.target.value }))}
          />
          <TextField
            id="wechat"
            label="微信号"
            variant="standard"
            value={info.wechat}
            onChange={(event) => store.dispatch(updateInfo({ wechat: event.target.value }))}
          />
          <TextField
            id="wechat"
            label="QQ 号（选填）"
            variant="standard"
            value={info.qq}
            onChange={(event) => store.dispatch(updateInfo({ qq: event.target.value }))}
          />
        </Stack>
      )}

      {status === 'filled' && (
        <Stack direction="row" spacing={2}>
          <TextField
            id="oiicpc"
            label="OI / ICPC 经历（选填）"
            variant="standard"
            value={info.oiicpc}
            onChange={(event) => store.dispatch(updateInfo({ oiicpc: event.target.value }))}
            sx={{
              flexGrow: 1,
            }}
          />
          <TextField
            id="codeforces"
            label="Codeforces 账号（选填）"
            variant="standard"
            value={info.codeforces}
            onChange={(event) => store.dispatch(updateInfo({ codeforces: event.target.value }))}
          />
        </Stack>
      )}

      {status === 'filled' && (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack direction="row" spacing={2}>
          <DatePicker
            views={['year']}
            label="毕业年份"
            minDate={new Date('2020-06-01')}
            maxDate={new Date('2030-06-01')}
            value={new Date(String(info.graduate_time))}
            onChange={(date) => {
              if (date) {
                store.dispatch(updateInfo({
                  graduate_time: date.getFullYear(),
                }));
              }
            }}
            renderInput={
                (params) => (
                  <TextField
                    {...params}
                    helperText={null}
                    variant="standard"
                    sx={{
                      flexGrow: 1,
                    }}
                  />
                )
            }
          />
          <TextField
            id="grade"
            label="当前年级"
            variant="standard"
            value={info.grade}
            onChange={(event) => store.dispatch(updateInfo({ grade: event.target.value }))}
          />
        </Stack>
      </LocalizationProvider>
      )}

      {status === 'filled' && (
        <Stack
          direction="row"
          spacing={2}
        >
          <FormControl
            variant="standard"
            sx={{
              flexGrow: 1,
            }}
          >
            <InputLabel id="type-label">选手类型</InputLabel>
            <Select
              id="type"
              label="选手类型"
              labelId="type-label"
              value={info.usertype}
              onChange={(event) => store.dispatch(updateInfo({ usertype: event.target.value }))}
            >
              <MenuItem value="a">目前就读于清华大学</MenuItem>
              <MenuItem value="b">目前就读于其他大学</MenuItem>
              <MenuItem value="c">不是在读大学生</MenuItem>
            </Select>
          </FormControl>
          {/* <Stack direction="column">
            <Typography
              variant="caption"
              color="text.secondary"
            >
              你可以上传简历，但是不要超过 10M
            </Typography>
            <Stack direction="row">
              <LoadingButton
                variant="text"
                size="medium"
                loading={downloadingResume}
                disabled={!hasResume}
                onClick={() => {
                  setDownloadingResume(true);
                  downloadResume(token)
                    .then(() => {
                      store.dispatch(setSuccess('开始下载'));
                    })
                    .catch((error) => {
                      store.dispatch(setError(error.message));
                    })
                    .finally(() => {
                      setDownloadingResume(false);
                    });
                }}
              >
                下载简历
              </LoadingButton>
              <Box sx={{ flexGrow: 1 }} />
              <LoadingButton
                variant="text"
                size="medium"
                component="label"
                loading={uploadingResume}
              >
                上传简历
                <input
                  type="file"
                  accept="application/pdf"
                  id="resumeSelecter"
                  onChange={(event) => {
                    const resumeFile = event.target.files[0];
                    setUploadingResume(true);
                    uploadUserResume(token, resumeFile)
                      .then(() => fetchUserInfoRequest(token))
                      .then((userInfo) => store.dispatch(fillUserInfo(userInfo)))
                      .then(() => {
                        store.dispatch(setSuccess('上传成功'));
                      })
                      .catch((error) => {
                        store.dispatch(setError(error.message));
                      })
                      .finally(() => {
                        setUploadingResume(false);
                      });
                  }}
                  hidden
                />
              </LoadingButton>
            </Stack>
          </Stack> */}
        </Stack>
      )}

      {status === 'filled' && (
        <Stack
          direction="row"
          spacing={2}
        >
          <FormControl
            variant="standard"
            sx={{
              flexGrow: 1,
            }}
          >
            <InputLabel id="clothingSize-label">衣服尺码</InputLabel>
            <Select
              id="clothingSize"
              label="衣服尺寸"
              labelId="clothingSize-label"
              value={info.clothingSize || 'unknown'}
              onChange={(event) => store.dispatch(updateInfo({ clothingSize: event.target.value }))}
            >
              <MenuItem value="S">S</MenuItem>
              <MenuItem value="M">M</MenuItem>
              <MenuItem value="L">L</MenuItem>
              <MenuItem value="XL">XL</MenuItem>
              <MenuItem value="2XL">2XL</MenuItem>
              <MenuItem value="3XL">3XL</MenuItem>
              <MenuItem value="4XL">4XL</MenuItem>
              <MenuItem value="unknown">未知</MenuItem>
            </Select>
          </FormControl>
          <TextField
            id="location"
            label="收货地址"
            variant="standard"
            value={info.location}
            onChange={(event) => store.dispatch(updateInfo({ location: event.target.value }))}
            sx={{
              flexGrow: 1,
            }}
          />
          {false && (
            <Stack direction="column">
              <Typography
                variant="caption"
                color="text.secondary"
              >
                上传电子签名
              </Typography>
              <Stack direction="row">
                <LoadingButton
                  variant="text"
                  size="medium"
                  loading={downloadingSignature}
                  disabled={!hasSignature}
                  onClick={() => {
                    setDownloadingSignature(true);
                    downloadSignature(token)
                      .then(() => {
                        store.dispatch(setSuccess('开始下载'));
                      })
                      .catch((error) => {
                        store.dispatch(setError(error.message));
                      })
                      .finally(() => {
                        setDownloadingSignature(false);
                      });
                  }}
                >
                  下载电子签名
                </LoadingButton>
                <Box sx={{ flexGrow: 1 }} />
                <LoadingButton
                  variant="text"
                  size="medium"
                  component="label"
                  loading={uploadingSignature}
                >
                  上传电子签名
                  <input
                    type="file"
                    accept="image/png"
                    id="signatureSelecter"
                    onChange={(event) => {
                      const signatureFile = event.target.files[0];
                      setUploadingSignature(true);
                      uploadUserSignature(token, signatureFile)
                        .then(() => fetchUserInfoRequest(token))
                        .then((userInfo) => store.dispatch(fillUserInfo(userInfo)))
                        .then(() => {
                          store.dispatch(setSuccess('上传成功'));
                        })
                        .catch((error) => {
                          store.dispatch(setError(error.message));
                        })
                        .finally(() => {
                          setUploadingSignature(false);
                        });
                    }}
                    hidden
                  />
                </LoadingButton>
              </Stack>
            </Stack>
          )}
        </Stack>
      )}

      {status === 'filled' && (
        <Stack direction="row" spacing={2}>
          <TextField
            id="wishes"
            label="有没有想让我们知道的？（活动许愿池，选填）"
            variant="standard"
            value={info.wishes}
            onChange={(event) => store.dispatch(updateInfo({ wishes: event.target.value }))}
            sx={{
              flexGrow: 1,
            }}
          />
        </Stack>
      )}

      {/* {status === 'filled' && info.usertype !== 'a' && (
        <Stack
          direction="column"
          spacing={2}
        >
          <Typography
            variant="caption"
            color="text.secondary"
          >
            以下信息仅用于预约入校（若不填无法入校）
          </Typography>
          <Stack
            direction="row"
            spacing={2}
          >
            <TextField
              id="name"
              label="真实姓名"
              variant="standard"
              value={info.name}
              disabled
            />
            <TextField
              id="id_card"
              label="身份证号"
              variant="standard"
              value={info.id_card}
              onChange={(event) => store.dispatch(
                updateInfo({
                  id_card: event.target.value,
                }),
              )}
              sx={{
                flexGrow: 1,
              }}
            />
          </Stack>
          <Stack
            direction="row"
            spacing={2}
          >
            <TextField
              id="phone"
              label="手机号"
              variant="standard"
              value={info.phone}
              disabled
            />
            <TextField
              id="workplace"
              label="工作单位"
              variant="standard"
              value={info.workplace}
              onChange={(event) => store.dispatch(updateInfo({ workplace: event.target.value }))}
              sx={{
                flexGrow: 1,
              }}
            />
          </Stack>
          <TextField
            id="address"
            label="居住地"
            variant="standard"
            value={info.address}
            onChange={(event) => store.dispatch(updateInfo({ address: event.target.value }))}
            sx={{
              flexGrow: 1,
            }}
          />
        </Stack>
      )} */}

      {status === 'filled' && (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <LoadingButton
            loading={submitting}
            onClick={() => {
              setSubmitting(true);
              updateUserInfoRequest(info, token)
                .catch((error) => {
                  store.dispatch(setError(error.message));
                  setSubmitting(false);
                })
                .then(() => {
                  store.dispatch(setSuccess('个人信息更新成功'));
                  setSubmitting(false);
                });
            }}
          >
            更新个人信息
          </LoadingButton>
          <Box sx={{ flexGrow: 1 }} />
          <Chip
            color="success"
            label={isAdmin ? '管理员' : '选手'}
            variant="outlined"
          />
        </Stack>
      )}

    </Stack>
  );
}

export default IndividualProfile;
