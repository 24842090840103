import React from 'react';
import {
  withRouter,
} from 'react-router';
import {
  Switch,
  Route,
} from 'react-router-dom';

import Notice, { NoticeEdit } from './notice';
import SignIn from './sign-in';
import SignUp from './sign-up';
import Profile from './profile';
import ResetPassword from './reset-password';

function Main() {
  return (
    <Switch>

      <Route path="/sign-in">
        <SignIn />
      </Route>

      <Route path="/sign-up">
        <SignUp />
      </Route>

      <Route path="/profile">
        <Profile />
      </Route>

      <Route path="/notice-edit/:id">
        <NoticeEdit />
      </Route>

      <Route path="/notice-edit">
        <NoticeEdit />
      </Route>

      <Route path="/reset-password">
        <ResetPassword />
      </Route>

      <Route path="/">
        <Notice />
      </Route>

    </Switch>
  );
}

export default withRouter(Main);
