import { createSlice } from '@reduxjs/toolkit';

export const ToastSlice = createSlice({
  name: 'toast',
  initialState: {
    open: false,
    severity: 'success',
    message: '',
  },
  reducers: {
    setError: (state, action) => {
      state.open = true;
      state.severity = 'error';
      state.message = action.payload;
    },
    setSuccess: (state, action) => {
      state.open = true;
      state.severity = 'success';
      state.message = action.payload;
    },
    clear: (state) => {
      state.open = false;
    },
  },
});

export const {
  setSuccess,
  setError,
  clear,
} = ToastSlice.actions;

export default ToastSlice.reducer;
