import React, { useState } from 'react';
import {
  TextField,
  Stack,
  CircularProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import { fetchAdminInfo } from './rest';
import store from '../store';
import { setError, setSuccess } from '../store/toast';
import { fillAdminInfo } from '../store/admin';

function AdminProfile() {
  const info = useSelector((state) => state.admin.info);
  const token = useSelector((state) => state.user.token);
  const status = useSelector((state) => state.admin.status);

  const [fetching, setFetching] = useState(false);

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        mt: 1,
      }}
    >
      {status !== 'filled' && (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Stack>
      )}

      {status === 'filled' && (
        <Stack
          direction="row"
          spacing={2}
        >
          <TextField
            id="all_user_num"
            label="注册的用户"
            variant="standard"
            value={info.all_user_num}
            disabled
            sx={{
              flexGrow: 1,
            }}
          />
          <TextField
            id="verify_user_num"
            label="完善信息的用户"
            variant="standard"
            value={info.verify_user_num}
            disabled
            sx={{
              flexGrow: 1,
            }}
          />
        </Stack>
      )}

      {status === 'filled' && (
        <Stack
          direction="row"
          spacing={2}
        >
          <TextField
            id="all_team_num"
            label="注册的队伍"
            variant="standard"
            value={info.all_team_num}
            disabled
            sx={{
              flexGrow: 1,
            }}
          />
          <TextField
            id="verify_team_num"
            label="确认参赛的队伍"
            variant="standard"
            value={info.verify_team_num}
            disabled
            sx={{
              flexGrow: 1,
            }}
          />
        </Stack>
      )}

      {status === 'filled' && (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <LoadingButton
            loading={fetching}
            onClick={() => {
              setFetching(true);
              fetchAdminInfo(token)
                .catch((error) => {
                  store.dispatch(setError(error.message));
                  setFetching(false);
                })
                .then((NewInfo) => {
                  store.dispatch(fillAdminInfo(NewInfo));
                  store.dispatch(setSuccess('已获取最新状态'));
                  setFetching(false);
                });
            }}
          >
            刷新以查看最新情况（请勿频繁使用以防卡顿）
          </LoadingButton>
        </Stack>
      )}

    </Stack>
  );
}

export default AdminProfile;
