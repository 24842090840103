import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import qs from 'qs';
import config from '../config.json';

axios.defaults.baseURL = config.baseURL;

export const exportSignupInfo = async (token) => axios.get('/user/get', {
  responseType: 'blob',
  headers: {
    Authorization: `Bearer ${token}`,
  },
})
  .catch((error) => {
    if (error.request) {
      throw Error('网络连接错误');
    } else if (error.response) {
      throw Error('数据库错误，无法导出');
    }
    throw Error('未知错误');
  })
  .then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'sign-up.xlsx');
    document.body.appendChild(link);
    link.click();
  });

export const publishNoticeRequest = async (title, content, token) => {
  if (config.mocking.includes('notice')) {
    const mock = new MockAdapter(axios);
    mock.onPost('/announcement/publish')
      .reply(async () => {
        await new Promise((resolve) => setTimeout(resolve, 500));
        return [200];
      });
  }
  return axios.post('/announcement/publish', qs.stringify({
    title,
    content,
  }), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        if (error.response) {
          throw Error('无法发布公告');
        } else if (error.request) {
          throw Error('网络连接错误');
        }
        throw Error('未知错误');
      }
    });
};

export const fetchNoticeRequest = async (token) => {
  if (config.mocking.includes('notice')) {
    const mock = new MockAdapter(axios);
    mock.onGet('/announcement/get')
      .reply(async () => {
        await new Promise((resolve) => setTimeout(resolve, 500));
        return [200, {
          announcements: [
            {
              id: 1,
              title: 'announcements one',
              content: 'nothing',
              author: 'shzr',
              timestamp: '2021/11/5',
            },
            {
              id: 2,
              title: 'announcements two',
              content: 'nothing',
              author: 'shzr',
              timestamp: '2021/11/6',
            },
          ],
        }];
      });
  }
  return axios.get('/announcement/get', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        if (error.response) {
          throw Error('无法获取公告，请稍后再试');
        } else if (error.request) {
          throw Error('网络连接错误');
        }
        throw Error('未知错误');
      }
    });
};

export const updateNoticeRequest = async (id, title, content, token) => {
  if (config.mocking.includes('notice')) {
    const mock = new MockAdapter(axios);
    mock.onPost('/announcement/publish')
      .reply(async () => {
        await new Promise((resolve) => setTimeout(resolve, 500));
        return [200, { jwt_token: 'sample_token' }];
      });
  }
  return axios.post('/announcement/update', qs.stringify({
    id,
    title,
    content,
  }), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      if (error.request) {
        throw Error('网络连接错误');
      }
      throw Error('未知错误');
    });
};

export const deleteNoticeRequest = async (id, token) => {
  if (config.mocking.includes('notice')) {
    const mock = new MockAdapter(axios);
    mock.onPost('/announcement/delete')
      .reply(async () => {
        await new Promise((resolve) => setTimeout(resolve, 500));
        return [200];
      });
  }
  return axios.post('/announcement/delete', qs.stringify({
    id,
  }), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      if (error.request) {
        throw Error('网络连接错误');
      }
      throw Error('未知错误');
    });
};

export default {
  publishNoticeRequest,
  fetchNoticeRequest,
  updateNoticeRequest,
  deleteNoticeRequest,
  exportSignupInfo,
};
