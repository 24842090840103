import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Stack,
  TextField,
} from '@mui/material';
import {
  LoadingButton,
} from '@mui/lab';
import {
  useHistory,
} from 'react-router-dom';
import {
  signUpRequest,
  emailRequest,
} from './rest';
import cover from '../image/cover.login.png';
import store from '../store';
import {
  setError,
  setSuccess,
} from '../store/toast';
import {
  validateUsername,
  validateEmail,
  validateVerifyCode,
  validatePassword,
  validateRedundantPassword,
} from '../validate';

export default function SignUp() {
  const [form, setForm] = useState({
    username: '',
    email: '',
    verifyCode: '',
    step: 1,
    password: '',
    redundantPassword: '',
  });

  const [sendingVerifyCode, setSendingVerifyCode] = useState(false);
  const [Submitting, setSubmit] = useState(false);

  const emailValidationMessage = () => validateEmail(form.email);
  const validateVerifyCodeMessage = () => validateVerifyCode(form.verifyCode);
  const validatedFirstStep = () => emailValidationMessage().length === 0
    && validateVerifyCodeMessage().length === 0;

  const passwordValidationMessage = () => validatePassword(form.password);
  const usernameValidationMessage = () => validateUsername(form.username);
  const redundantPasswordValidationMessage = () => validateRedundantPassword(
    form.password,
    form.redundantPassword,
  );
  const validatedSecondStep = () => passwordValidationMessage().length === 0
    && redundantPasswordValidationMessage().length === 0;

  const history = useHistory();

  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false; };
  }, []);

  return (
    <Stack direction="row" alignItems="center" justifyContent="center">
      <Card
        sx={{
          width: 350,
          mt: 5,
        }}
      >
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={2}
          sx={{ flexGrow: 1 }}
        >
          <CardMedia
            component="img"
            image={cover}
          />
        </Stack>
        {form.step === 1 && (
          <CardContent>
            <Stack direction="column" spacing={1}>
              <Stack
                direction="row"
                spacing={2}
              >
                <TextField
                  sx={{
                    flexGrow: 1,
                  }}
                  id="email"
                  label="邮箱"
                  variant="standard"
                  value={form.email}
                  onChange={(event) => setForm({ ...form, email: event.target.value })}
                  helperText={emailValidationMessage()}
                  error={(emailValidationMessage().length !== 0) && (form.email.length !== 0)}
                />
                <LoadingButton
                  loading={sendingVerifyCode}
                  disabled={emailValidationMessage().length !== 0}
                  onClick={() => {
                    setSendingVerifyCode(true);
                    emailRequest(form.email)
                      .then(() => {
                        store.dispatch(setSuccess('发送成功'));
                      })
                      .catch((error) => {
                        store.dispatch(setError(error.message));
                      })
                      .then(() => {
                        setSendingVerifyCode(false);
                      });
                  }}
                >
                  发送验证码
                </LoadingButton>
              </Stack>
              <TextField
                id="verifyCode"
                label="验证码"
                variant="standard"
                value={form.verifyCode}
                onChange={(event) => setForm({ ...form, verifyCode: event.target.value })}
                helperText={validateVerifyCodeMessage()}
                error={(validateVerifyCodeMessage().length !== 0) && (form.verifyCode.length !== 0)}
              />
            </Stack>
          </CardContent>
        )}

        {form.step === 2 && (
          <CardContent>
            <Stack direction="column" spacing={1}>
              <TextField
                id="username"
                label="用户名"
                variant="standard"
                value={form.username}
                onChange={(event) => setForm({ ...form, username: event.target.value })}
                helperText={usernameValidationMessage()}
                error={(usernameValidationMessage().length !== 0) && (form.username.length !== 0)}
              />
              <TextField
                id="password"
                label="密码"
                type="password"
                variant="standard"
                value={form.password}
                onChange={(event) => setForm({ ...form, password: event.target.value })}
                helperText={passwordValidationMessage()}
                error={(passwordValidationMessage().length !== 0) && (form.password.length !== 0)}
              />
              <TextField
                id="redundantPassword"
                label="验证密码"
                type="password"
                variant="standard"
                value={form.redundantPassword}
                onChange={(event) => setForm({ ...form, redundantPassword: event.target.value })}
                helperText={redundantPasswordValidationMessage()}
                error={(redundantPasswordValidationMessage().length !== 0)
                  && (form.redundantPassword.length !== 0)}
              />
            </Stack>
          </CardContent>
        )}

        {(form.step === 1) && (
          <CardActions>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              width="100%"
              spacing={1}
            >
              <Box sx={{ flexGrow: 1 }} />
              <LoadingButton
                disabled={!validatedFirstStep()}
                onClick={() => {
                  setForm({ ...form, step: 2 });
                }}
              >
                下一步
              </LoadingButton>
            </Stack>
          </CardActions>
        )}

        {(form.step === 2) && (
          <CardActions>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              width="100%"
              spacing={1}
            >
              <Box sx={{ flexGrow: 1 }} />
              <LoadingButton
                sx={{
                  color: 'secondary.main',
                }}
                disabled={!validatedFirstStep()}
                onClick={() => {
                  setForm({ ...form, step: 1 });
                }}
              >
                上一步
              </LoadingButton>
              <LoadingButton
                disabled={!validatedSecondStep()}
                loading={Submitting}
                onClick={() => {
                  setSubmit(true);
                  signUpRequest(form.email, form.username, form.password, form.verifyCode)
                    .then(() => {
                      store.dispatch(setSuccess('注册成功'));
                      history.push('/sign-in');
                    })
                    .catch((error) => {
                      store.dispatch(setError(error.message));
                    })
                    .then(() => {
                      setSubmit(false);
                    });
                }}
              >
                注册
              </LoadingButton>
            </Stack>
          </CardActions>
        )}
      </Card>
    </Stack>
  );
}
