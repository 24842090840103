import { createSlice } from '@reduxjs/toolkit';

export const UserSlice = createSlice({
  name: 'user',
  initialState: {
    info: {},
    token: '',
    status: 'empty',
    // empty: no token
    // detached: have token, no info
    // filled: token + info
  },
  reducers: {
    fillUserInfo: (state, action) => {
      state.info = action.payload;
      state.status = 'filled';
    },
    setToken: (state, action) => {
      const { token } = action.payload;
      state.info = {};
      state.token = token;
      state.status = 'detached';
      localStorage.setItem('token', token);
    },
    initFromLocalStorage: (state) => {
      const token = localStorage.getItem('token');
      state.info = {};
      state.token = token;
      if (token != null && token.length > 0) {
        state.status = 'detached';
      }
    },
    clearToken: (state) => {
      state.info = {};
      state.token = '';
      state.status = 'empty';
      localStorage.setItem('token', '');
    },
    updateInfo: (state, action) => {
      state.info = {
        ...state.info,
        ...action.payload,
      };
    },
  },
});

export const {
  fillUserInfo,
  initFromLocalStorage,
  setToken,
  clearToken,
  updateInfo,
} = UserSlice.actions;

export default UserSlice.reducer;
