import React, { useEffect, useRef, useState } from 'react';
import {
  Alert,
  Button,
  Box,
  Card,
  Stack,
  List,
  ListItem,
  CircularProgress,
} from '@mui/material';
import {
  useHistory,
} from 'react-router-dom';
import {
  LoadingButton,
} from '@mui/lab';
import { useSelector } from 'react-redux';
import store from '../store';
import {
  setError,
} from '../store/toast';
import { exportSignupInfo } from './rest';
import Entry from './entry';
// import sixieLogo from '../image/sixie.jpg';
// import zhengdingLogo from '../image/zhengdingsimu.png';
import huaweiLogo from '../image/huawei.png';
// import jumptradingLogo from '../image/jumptrading.png';
// import tusimpleLogo from '../image/tusimple.png';
// import minghongLogo from '../image/minghong.jpg';
import thusaacLogo from '../image/thusaac.png';
import ubiquantLogo from '../image/ubiquant.png';

export { default as NoticeEdit } from './edit';
export default function NoticeRead() {
  const noticeList = useSelector((state) => state.notice.noticeList);
  const isAdmin = useSelector((state) => state.user.info.is_admin);
  const history = useHistory();
  const isMounted = useRef(false);
  const fetchingNotice = useSelector((state) => state.notice.status === 'fetching');
  const token = useSelector((state) => state.user.token);
  const [downloadingSignUpForm, setDownloadingSignUpForm] = useState(false);

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false; };
  }, []);

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      direction="column"
      spacing={2}
      sx={{
        mt: 2,
      }}
    >
      {isAdmin && (
        <Alert
          sx={{
            width: 700,
          }}
          action={(
            <Stack
              direction="row"
            >
              <Button
                size="small"
                onClick={() => {
                  history.push('/notice-edit');
                }}
              >
                发布公告
              </Button>
              <LoadingButton
                size="small"
                loading={downloadingSignUpForm}
                onClick={() => {
                  setDownloadingSignUpForm(true);
                  exportSignupInfo(token)
                    .catch((error) => {
                      store.dispatch(setError(error.message));
                    })
                    .finally(() => setDownloadingSignUpForm(false));
                }}
              >
                导出报名表格
              </LoadingButton>
            </Stack>
          )}
        >
          您是管理员，可以发布公告或者导出报名表格
        </Alert>
      )}

      {fetchingNotice && (
        <CircularProgress />
      )}

      {!fetchingNotice && (
      <Card
        sx={{
          p: 1,
          boxShadow: 0,
        }}
      >
        <Stack
          direction="row"
          spacing={7}
          alignItems="center"
        >
          <Box
            component="img"
            src={thusaacLogo}
            alt="logo of thusaac"
            sx={{
              height: 90,
            }}
            paddingLeft={10}
          />
          <Stack
            direction="row"
            spacing={6}
            justifyContent="center"
            alignItems="flex-start"
          >
            <Stack
              direction="column"
              spacing={1}
              alignItems="center"
            >
              <Box
                textAlign="center"
                fontWeight="bold"
                fontSize={20}
              >
                钻石赞助商
              </Box>
              <Box
                component="img"
                src={huaweiLogo}
                alt="logo of huawei"
                sx={{
                  height: 40,
                }}
              />
            </Stack>

            <Stack
              direction="column"
              spacing={1}
              alignItems="center"
            >
              <Box
                textAlign="center"
                fontWeight="bold"
                fontSize={20}
              >
                金牌赞助商
              </Box>
              <Box
                component="img"
                src={ubiquantLogo}
                alt="logo of ubiquant"
                sx={{
                  height: 50,
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Card>
      )}

      {!fetchingNotice && (
        <List
          dense
        >
          {
            noticeList.map((n) => (
              <ListItem key={n.id}>
                <Entry
                  title={n.title}
                  timestamp={n.timestamp}
                  content={n.content}
                  id={n.id}
                />
              </ListItem>
            ))
          }
        </List>
      )}

    </Stack>
  );
}
