import axios from 'axios';
import { md5 } from 'hash-wasm';
import MockAdapter from 'axios-mock-adapter';
import qs from 'qs';
import config from '../config.json';

axios.defaults.baseURL = config.baseURL;

export const signUpRequest = async (Email, Username, Password, VerifyCode) => {
  if (config.mocking.includes('sign-up')) {
    const mock = new MockAdapter(axios);
    mock.onPost('/user/register')
      .reply(async () => {
        await new Promise((resolve) => setTimeout(resolve, 500));
        return [200, { jwt_token: 'sample_token' }];
      });
  }
  return axios.post('/user/register', qs.stringify({
    email: Email,
    username: Username,
    password: await md5(Password),
    verify_code: VerifyCode,
  }))
    .catch((error) => {
      if (error.request) {
        throw Error(error.response.data.error_info);
      }
      throw Error('未知错误');
    })
    .then((response) => response.data);
};

export const emailRequest = async (Email) => {
  if (config.mocking.includes('check-email')) {
    const mock = new MockAdapter(axios);
    mock.onPost('/auth/register/verify')
      .reply(async () => {
        await new Promise((resolve) => setTimeout(resolve, 500));
        return [200, { jwt_token: 'sample_token' }];
      });
  }
  return axios.post('/auth/register/verify', qs.stringify({
    email: Email,
  })).catch((error) => {
    if (error.request) {
      throw Error(error.response.data.error_info);
    }
    throw Error('未知错误');
  })
    .then((response) => response.data);
};

export default { signUpRequest, emailRequest };
