import { createSlice } from '@reduxjs/toolkit';

export const AdminSlice = createSlice({
  name: 'admin',
  initialState: {
    status: 'empty',
    info: {
      all_user_num: -1,
      all_team_num: -1,
      verify_user_num: -1,
      verify_team_num: -1,
    },
  },
  reducers: {
    fillAdminInfo(state, action) {
      state.info = action.payload;
      state.status = 'filled';
    },
  },
});

export const {
  fillAdminInfo,
} = AdminSlice.actions;

export default AdminSlice.reducer;
