import axios from 'axios';
import qs from 'qs';
import { md5 } from 'hash-wasm';
import config from '../config.json';

axios.defaults.baseURL = config.baseURL;

export const sendResetPasswordEmailRequest = async (email) => (axios.post('/auth/reset_password', qs.stringify({
  email,
}))
  .catch((error) => {
    if (error.response) {
      throw Error('邮箱错误');
    } else if (error.request) {
      throw Error('网络连接错误');
    }
    throw Error('未知错误');
  })
  .then((response) => response.data));

export const resetPasswordRequest = async (email, verifyCode, password) => (axios.post('/auth/reset_password/verify', qs.stringify({
  email,
  verify_code: verifyCode,
  password: await md5(password),
}))
  .catch((error) => {
    if (error.response) {
      throw Error('验证码错误');
    } else if (error.request) {
      throw Error('网络连接错误');
    }
    throw Error('未知错误');
  })
  .then((response) => response.data));

export default {
  sendResetPasswordEmailRequest,
  resetPasswordRequest,
};
